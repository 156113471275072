export const SHOP_GRAPHQL_ENDPOINT =
    process.env.REACT_APP_STORE_URL + 'graphql';

export const PATTERN_TRENDING_ATTRIBUTE_CODE = 'trending_score';
export const PATTERN_NAME_ATTRIBUTE_CODE = 'name';
export const PATTERN_POPULARITY_CODE = 'popularity_score';
export const PATTERN_ADDED_ON_CODE = 'added_on';

import baby_jpg from '_assets/img/collection_lib/baby.jpg';
import baby_avif from '_assets/img/collection_lib/baby.avif';
import junior_jpg from '_assets/img/collection_lib/junior.jpg';
import junior_avif from '_assets/img/collection_lib/junior.avif';
import men_jpg from '_assets/img/collection_lib/men.jpg';
import men_avif from '_assets/img/collection_lib/men.avif';
import women_jpg from '_assets/img/collection_lib/women.jpg';
import women_avif from '_assets/img/collection_lib/women.avif';

import beginner_jpg from '_assets/img/collection_lib/beginner.jpg';
import beginner_avif from '_assets/img/collection_lib/beginner.avif';
import cables_jpg from '_assets/img/collection_lib/cables.jpg';
import cables_avif from '_assets/img/collection_lib/cables.avif';
import classics_jpg from '_assets/img/collection_lib/classics.jpg';
import classics_avif from '_assets/img/collection_lib/classics.avif';
import colorwork_jpg from '_assets/img/collection_lib/colorwork.jpg';
import colorwork_avif from '_assets/img/collection_lib/colorwork.avif';
import scrapyarn_jpg from '_assets/img/collection_lib/scrapyarn.jpg';
import scrapyarn_avif from '_assets/img/collection_lib/scrapyarn.avif';
import stripes_jpg from '_assets/img/collection_lib/stripes.jpg';
import stripes_avif from '_assets/img/collection_lib/stripes.avif';
import crocheting_jpg from '_assets/img/collection_lib/crocheting.jpg';
import crocheting_avif from '_assets/img/collection_lib/crocheting.avif';
import texturedknitting_jpg from '_assets/img/collection_lib/texturedknitting.jpg';
import texturedknitting_avif from '_assets/img/collection_lib/texturedknitting.avif';
import chunky_jpg from '_assets/img/collection_lib/chunky.jpg';
import chunky_avif from '_assets/img/collection_lib/chunky.avif';

type urls = { avif: string; jpg: string };

export const SuitableForThumbnail: { [key: string]: urls } = {
    baby: { jpg: baby_jpg, avif: baby_avif },
    barn: { jpg: junior_jpg, avif: junior_avif },
    junior: { jpg: junior_jpg, avif: junior_avif },
    men: { jpg: men_jpg, avif: men_avif },
    herre: { jpg: men_jpg, avif: men_avif },
    women: { jpg: women_jpg, avif: women_avif },
    dame: { jpg: women_jpg, avif: women_avif },
};

export const KnittingCollectionThumbnail: {
    [keys: string]: urls;
} = {
    beginner: { jpg: beginner_jpg, avif: beginner_avif },
    nybegynner: { jpg: beginner_jpg, avif: beginner_avif },
    cables: { jpg: cables_jpg, avif: cables_avif },
    fletter: { jpg: cables_jpg, avif: cables_avif },
    classics: { jpg: classics_jpg, avif: classics_avif },
    klassikere: { jpg: classics_jpg, avif: classics_avif },
    colorwork: { jpg: colorwork_jpg, avif: colorwork_avif },
    mønsterstrikk: { jpg: colorwork_jpg, avif: colorwork_avif },
    scrapyarn: { jpg: scrapyarn_jpg, avif: scrapyarn_avif },
    restegarn: { jpg: scrapyarn_jpg, avif: scrapyarn_avif },
    stripes: { jpg: stripes_jpg, avif: stripes_avif },
    striper: { jpg: stripes_jpg, avif: stripes_avif },
    crocheting: { jpg: crocheting_jpg, avif: crocheting_avif },
    hekling: { jpg: crocheting_jpg, avif: crocheting_avif },
    texturedknitting: {
        jpg: texturedknitting_jpg,
        avif: texturedknitting_avif,
    },
    strukturstrikk: { jpg: texturedknitting_jpg, avif: texturedknitting_avif },
    chunky: { jpg: chunky_jpg, avif: chunky_avif },
};

export const ALLOWED_PATTERN_FILTERS = [
    'designer',
    'category_uid',
    'languages',
    'suitable_for',
    'knitting_collection',
    'gauge_metric',
];
export const RECENTLY_VIEWED = 'recently_viewed';
